import React, { Fragment, useEffect } from 'react'
import Input from 'components/global/Input'
import Page from 'components/global/Page'
import { useState } from 'react'
import { MoveRight } from 'lucide-react'
import useSettings from '_hooks/useSettings'
import formatDate from '_utils/formatDate'
import { useParams } from 'react-router-dom'
import AdminHeader from 'components/admin/AdminHeader'
import TableInput from 'components/admin/TableInput'
import adjustTime from '_utils/adjustTime'
import DatePicker from 'react-datepicker'
const StaffTimeSheet = () => {
  const [editable, setEditable] = useState(false)
  const { id } = useParams()
  const handleEditableContent = () => {
    setEditable(true)
  }
  const [maxBreakLength, setMaxBreakLength] = useState(1)
  const [payload, setPayload] = useState([])
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')

  const handleClosePopup = () => {
    setEditable(false)
  }

  const { timeSheet, fetchMyTimeSheet, editTimeSheet } = useSettings()

  useEffect(() => {
    if (id) {
      fetchMyTimeSheet(id)
    }
  }, [id])

  useEffect(() => {
    if (from && to) {
      fetchMyTimeSheet(id, { from, to })
    }
  }, [from, to])

  useEffect(() => {
    timeSheet?.timesheet?.forEach((item) => {
      if (item?.time.length > maxBreakLength) {
        setMaxBreakLength(item?.time?.length)
      }
    })
  }, [timeSheet])

  return (
    <Page>
      <div id='header' className='w-full'>
        <AdminHeader />
      </div>
      <div className='w-full flex flex-col h-88vh gap-y-4 justify-between '>
        {/* Table content  */}
        <div className='w-full flex justify-between'>
          <div className='flex items-end gap-x-4'>
            <span className='flex items-center gap-x-2 relative'>
              <h6 className='text-xs font-medium'>From:</h6>
              <DatePicker
                selected={from}
                onChange={(date) => {
                  setFrom(date)
                }}
                maxDate={to ? to : ''}
                className='border !border-gray-400 !rounded-xl font-normal !text-xs !py-2 hover:border-teal-400 cursor-pointer outline-none px-4 w-full no-calendar '
                placeholderText='mm/dd/yyy'
              />
            </span>

            <span className='flex items-center gap-x-2 relative'>
              <h6 className='text-xs font-medium'>To:</h6>
              <DatePicker
                selected={to}
                onChange={(date) => {
                  setTo(date)
                }}
                disabled={!from}
                minDate={from}
                className='border !border-gray-400 !rounded-xl font-normal !text-xs !py-2 hover:border-teal-400 cursor-pointer outline-none px-4 w-full no-calendar '
                placeholderText='mm/dd/yyy'
              />
            </span>
            <button
              onClick={() => editTimeSheet(payload)}
              className='py-1.5 px-4 rounded-xl border bg-text-dm-input shadow-buttonPrimary text-base font-semibold text-white'
            >
              update
            </button>
            <button
              onClick={() => window.location.reload()}
              className='py-1.5 px-4 rounded-xl bg-universal border border-text-dm-input text-base font-medium text-gray-400'
            >
              Refresh
            </button>
            <button className='py-2 px-4 rounded-xl bg-universal shadow-customBtnShadow flex flex-nowrap gap-x-2 text-xs font-medium items-center'>
              <img src='/assets/Icons/download.svg' alt='Download Icon' />
              Download
            </button>
          </div>
        </div>
        <div className='shadow-customBtnShadow rounded-xl h-full overflow-y-auto'>
          <table className='w-full rounded-xl'>
            <thead className='sticky top-0 bg-universal border'>
              <tr>
                <th className='border-r text-xs font-semibold p-2.5'></th>
                <th
                  className='border-r text-xs font-semibold p-2.5 text-black'
                  colSpan={maxBreakLength * 2}
                >
                  Working Time
                </th>
                <th
                  className='border-r text-xs font-semibold p-2.5 text-black'
                  colSpan='2'
                >
                  Total
                </th>
                <th className=' text-xs font-semibold p-2.5' colSpan='2'></th>
              </tr>
              <tr className='bg-hovered-bg'>
                <td className='border-r text-center text-xs font-normal p-2.5'>
                  Date
                </td>
                {[...Array(maxBreakLength).keys()]?.map((_, index) => {
                  return (
                    <Fragment key={index}>
                      <td className='border-r text-center text-xs font-normal p-2.5'>
                        Start Time
                      </td>
                      <td className='border-r text-center text-xs font-normal p-2.5'>
                        End Time
                      </td>
                    </Fragment>
                  )
                })}
                <td className='border-r text-center text-xs font-normal p-2.5'>
                  Break Time (Min)
                </td>
                <td className='border-r text-center text-xs font-normal p-2.5'>
                  Working Time (Min)
                </td>
                <td
                  className='border-r text-center text-xs font-normal p-2.5'
                  colSpan='2'
                >
                  Memo
                </td>
              </tr>
            </thead>
            <tbody>
              {timeSheet?.timesheet?.map((timeItem) => {
                let extraTds = maxBreakLength - timeItem?.time?.length
                return (
                  <tr key={timeItem.date} className='border border-b'>
                    <td className='border-r text-center text-xs font-normal p-2.5'>
                      {formatDate(timeItem.date)}
                    </td>
                    {timeItem.time?.map((item, index) => {
                      let lastIndex = timeItem.time.length - 1
                      let nextIndex = index >= lastIndex ? -1 : index + 1
                      let prevIndex = index > 0 ? index - 1 : -1
                      let endOfDay = new Date(timeItem?.date)
                      endOfDay.setHours(23, 59, 59, 999)
                      let startOfDate = new Date(timeItem?.date)
                      startOfDate.setHours(0, 0, 0, 0)
                      return (
                        <Fragment key={item?.id}>
                          <td className='border-r text-center text-xs font-normal p-2.5'>
                            <TableInput
                              value={item?.workStartTime}
                              editable={editable}
                              onClick={handleEditableContent}
                              edited={item?.workStartTimeIsEdited}
                              min={
                                prevIndex >= 0
                                  ? timeItem.time[prevIndex]?.workEndTime
                                  : startOfDate.toISOString()
                              }
                              max={
                                item?.workEndTime
                                  ? item?.workEndTime
                                  : endOfDay.toISOString()
                              }
                              onChange={(date) => {
                                let tempObj = {
                                  timesheetId: item?.id,
                                  workStartTime: new Date(
                                    adjustTime(date, timeItem?.date)
                                  ),
                                  oldStartTime: item?.workStartTime,
                                }
                                let tempArray =
                                  payload?.length > 0
                                    ? payload?.map((payloadItem) => {
                                        if (
                                          tempObj.timesheetId ===
                                          payloadItem.timesheetId
                                        ) {
                                          return { ...payloadItem, ...tempObj }
                                        } else {
                                          return { ...tempObj }
                                        }
                                      })
                                    : [tempObj]
                                setPayload(tempArray)
                              }}
                              popperContent={
                                <div className=' p-3 rounded-2xl text-start  border border-teal-500 bg-universal flex flex-col items-center gap-y-3'>
                                  <h6 className='w-full text-start'>Edited</h6>
                                  <div className='flex justify-between items-center gap-x-1'>
                                    <TableInput
                                      value={item?.oldStartTime}
                                      disabled={true}
                                      className='border border-gray-300 py-2 w-20 px-3 text-center bg-transparent rounded-xl outline-none'
                                    />
                                    <MoveRight className='text-red-500' />
                                    <TableInput
                                      value={item?.workStartTime}
                                      min={
                                        prevIndex >= 0
                                          ? timeItem.time[prevIndex]
                                              ?.workEndTime
                                          : startOfDate.toISOString()
                                      }
                                      max={
                                        item?.workEndTime
                                          ? item?.workEndTime
                                          : endOfDay.toISOString()
                                      }
                                      onChange={(date) => {
                                        let tempObj = {
                                          timesheetId: item?.id,
                                          workStartTime: new Date(
                                            adjustTime(date, timeItem?.date)
                                          ),
                                          oldStartTime: item?.workStartTime,
                                        }
                                        let tempArray =
                                          payload?.length > 0
                                            ? payload?.map((payloadItem) => {
                                                if (
                                                  tempObj.timesheetId ===
                                                  payloadItem.timesheetId
                                                ) {
                                                  return {
                                                    ...payloadItem,
                                                    ...tempObj,
                                                  }
                                                } else {
                                                  return { ...tempObj }
                                                }
                                              })
                                            : [tempObj]
                                        setPayload(tempArray)
                                      }}
                                      className='border border-gray-300 py-2 w-20 px-3 text-center bg-transparent rounded-xl outline-none'
                                    />
                                  </div>
                                  <div className='flex gap-x-2 w-full'>
                                    <button
                                      className='w-full py-2 px-3 bg-text-dm-input shadow-buttonPrimary rounded-xl text-white'
                                      onClick={() => {
                                        editTimeSheet(payload)
                                        handleClosePopup()
                                      }}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className='w-full py-2 px-3 bg-transparent rounded-xl text-gray-400 border border-text-dm-input'
                                      onClick={handleClosePopup}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              }
                            />
                          </td>
                          <td className='border-r text-center text-xs font-normal p-2.5'>
                            <TableInput
                              value={item?.workEndTime}
                              min={item?.workStartTime}
                              max={
                                nextIndex > 0
                                  ? timeItem.time[nextIndex]?.workStartTime
                                  : endOfDay.toISOString()
                              }
                              edited={item?.workEndTimeIsEdited}
                              editable={editable}
                              onClick={handleEditableContent}
                              onChange={(date) => {
                                let tempObj = {
                                  timesheetId: item?.id,
                                  workEndTime: new Date(
                                    adjustTime(date, timeItem?.date)
                                  ),
                                  oldEndTime: item?.workEndTime,
                                }
                                let tempArray =
                                  payload?.length > 0
                                    ? payload?.map((payloadItem) => {
                                        if (
                                          tempObj.timesheetId ===
                                          payloadItem.timesheetId
                                        ) {
                                          return { ...payloadItem, ...tempObj }
                                        } else {
                                          return { ...tempObj }
                                        }
                                      })
                                    : [tempObj]
                                setPayload(tempArray)
                              }}
                              popperContent={
                                <div className=' p-3 rounded-2xl text-start  border border-teal-500 bg-universal flex flex-col items-center gap-y-3'>
                                  <h6 className='w-full text-start'>Edited</h6>
                                  <div className='flex justify-between items-center gap-x-1'>
                                    <TableInput
                                      value={item?.oldEndTime}
                                      disabled={true}
                                      className='border border-gray-300 py-2 w-20 px-3 text-center bg-transparent rounded-xl outline-none'
                                    />
                                    <MoveRight className='text-red-500' />
                                    <TableInput
                                      value={item?.workEndTime}
                                      min={item?.workStartTime}
                                      max={
                                        nextIndex > 0
                                          ? timeItem.time[nextIndex]
                                              ?.workStartTime
                                          : endOfDay.toISOString()
                                      }
                                      onChange={(date) => {
                                        let tempObj = {
                                          timesheetId: item?.id,
                                          workEndTime: new Date(
                                            adjustTime(date, timeItem?.date)
                                          ),
                                          oldEndTime: item?.workEndTime,
                                        }
                                        let tempArray =
                                          payload?.length > 0
                                            ? payload?.map((payloadItem) => {
                                                if (
                                                  tempObj.timesheetId ===
                                                  payloadItem.timesheetId
                                                ) {
                                                  return {
                                                    ...payloadItem,
                                                    ...tempObj,
                                                  }
                                                } else {
                                                  return { ...tempObj }
                                                }
                                              })
                                            : [tempObj]
                                        setPayload(tempArray)
                                      }}
                                      className='border border-gray-300 py-2 w-20 px-3 text-center bg-transparent rounded-xl outline-none'
                                    />
                                  </div>
                                  <div className='flex gap-x-2 w-full'>
                                    <button
                                      className='w-full py-2 px-3 bg-text-dm-input shadow-buttonPrimary rounded-xl text-white'
                                      onClick={() => {
                                        editTimeSheet(payload)
                                        handleClosePopup()
                                      }}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className='w-full py-2 px-3 bg-transparent rounded-xl text-gray-400 border border-text-dm-input'
                                      onClick={() => {
                                        setPayload([])
                                        handleClosePopup()
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              }
                            />
                          </td>
                        </Fragment>
                      )
                    })}
                    {extraTds > 0 &&
                      [...Array(extraTds).keys()]?.map((_, index) => {
                        return (
                          <Fragment key={index}>
                            <td className='text-center text-xs border-r'>-</td>
                            <td className='text-center text-xs border-r'>-</td>
                          </Fragment>
                        )
                      })}
                    <td className='border-r text-center text-xs font-normal p-2.5'>
                      <TableInput
                        value={Number(timeItem?.totalBreakTime)}
                        type='number'
                        disabled={true}
                        editable={editable}
                        onClick={handleEditableContent}
                      />
                    </td>
                    <td className='border-r text-center text-xs font-normal p-2.5'>
                      <TableInput
                        value={Number(timeItem?.totalWorkingMinutes)}
                        type='number'
                        disabled={true}
                        editable={false}
                        onClick={handleEditableContent}
                      />
                    </td>
                    <td
                      className='text-center text-xs font-normal p-2.5 flex justify-center items-center'
                      colSpan='2'
                    >
                      <Input
                        value={timeItem?.time?.[0].note}
                        onChange={(e) => {
                          let tempObj = {
                            timesheetId: timeItem?.time?.[0]?.id,
                            note: e.target.value,
                          }
                          let tempArray =
                            payload?.length > 0
                              ? payload?.map((payloadItem) => {
                                  if (
                                    tempObj.timesheetId ===
                                    payloadItem.timesheetId
                                  ) {
                                    return { ...payloadItem, ...tempObj }
                                  } else {
                                    return { ...tempObj }
                                  }
                                })
                              : [tempObj]
                          setPayload(tempArray)
                        }}
                        className='!border-gray-300 !rounded-xl !py-2 !bg-transparent w-[90%]'
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {/* result */}
        <div className='shadow-customBtnShadow rounded-xl flex items-center justify-end p-2 gap-x-3'>
          <div className='border border-text-dm-input rounded-xl p-3 flex flex-col items-center justify-center gap-y-2'>
            <h3 className='text-base font-semibold'>
              {timeSheet?.overallTotalWorkingDays}
            </h3>
            <h6 className='text-xs font-medium'>Total Working Days</h6>
          </div>
          <div className='border border-text-dm-input rounded-xl p-3 flex flex-col items-center justify-center gap-y-2'>
            <h3 className='text-base font-semibold'>
              {timeSheet?.overallTotalBreakTime}
            </h3>
            <h6 className='text-xs font-medium'>Total Break Time</h6>
          </div>
          <div className='border border-text-dm-input rounded-xl p-3 px-4 flex flex-col items-center justify-center gap-y-2'>
            <h3 className='text-base font-semibold'>
              {timeSheet?.overallTotalWorkingMinutes}
            </h3>
            <h6 className='text-xs font-medium'>Total Working Time</h6>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default StaffTimeSheet
