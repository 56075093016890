function utcToDDMMYYYY(utcDateString) {
  const date = new Date(utcDateString) // Parse the UTC date string
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getUTCFullYear()

  return `${day}/${month}/${year}`
}

export default utcToDDMMYYYY
