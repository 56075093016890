import React, { useEffect, useState } from 'react'
import Input from 'components/global/Input'
import CustomSelect from 'components/global/CustomSelect'
import Page from 'components/global/Page'
import GlobalRadioButton from 'components/global/GlobalRadioButton'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axiosInstance from '_utils/axiosUtils'
import DataTable from 'react-data-table-component'
import actionTypeObj from '_utils/actionTypeObj'
import TextArea from 'components/global/TextArea'
// import CustomTrash from 'components/global/icons/CustomTrash'
import { GoTrash } from 'react-icons/go'
import getOnlyTime from '_utils/getOnlyTime'
import utcToDDMMYYYY from '_utils/utcToDDMMYYYY'

export default function Notification() {
  const [notificationType, setNotificationType] = useState('')
  const [template, setTemplate] = useState([])
  const [recurring, setRecurring] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [specificDateAndTime, setSpecificDateAndTime] = useState(new Date())
  const [selectedIds, setSelectedIds] = useState([])

  const notificationTypeArray = [
    {
      label: 'Server Maintanence Notification',
      value: 'server_maintenance',
    },
    { label: 'App Version Updates', value: 'app_version_update' },
    { label: 'Other', value: 'admin_announcement' },
  ]

  const [scheduleNotification, setScheduleNotification] =
    useState('immediately')

  const getTemplateByType = (type) => {
    axiosInstance
      .get(`/admin/notificationTemplate/${type}`)
      .then((res) => {
        setTemplate(res.data.data)
        setSelectedTemplate(res.data.data[0])
      })
      .catch((err) => console.log(err))
  }

  const getNotification = () => {
    axiosInstance
      .get('/admin/notification')
      .then((res) => {
        setData(res.data.data.notificationData)
      })
      .catch((err) => console.log(err))
  }

  const deleteNotification = () => {
    axiosInstance
      .delete('/admin/notification', {
        data: { ids: selectedIds },
      })
      .then(() => {
        let tempArray = data.filter((item) => !selectedIds.includes(item.id))
        setData(tempArray)
        setSelectedIds([])
      })
      .catch((err) => console.log(err))
  }

  const handleRadioChange = (val) => {
    setScheduleNotification(val)
  }

  useEffect(() => {
    if (notificationType) {
      getTemplateByType(notificationType)
    }
  }, [notificationType])

  useEffect(() => {
    setTemplate([])
    getNotification()
    setNotificationType('server_maintenance')
  }, [])

  useEffect(() => {
    if (notificationType === 'server_maintenance') {
      setServerNotification((prev) => ({
        ...prev,
        englishText: selectedTemplate?.enText,
        japaneseText: selectedTemplate?.jaText,
      }))
    }
    if (notificationType === 'app_version_update') {
      setAppVersionNotification((prev) => ({
        ...prev,
        englishText: selectedTemplate?.enText,
        japaneseText: selectedTemplate?.jaText,
        englishTitle: selectedTemplate?.enTitle,
        japaneseTitle: selectedTemplate?.jaTitle,
        englishUpdateBtn: selectedTemplate?.enBtnText,
        japaneseUpdateBtn: selectedTemplate?.jaBtnText,
      }))
    }
    if (notificationType === 'admin_announcement') {
      setOtherNotification((prev) => ({
        ...prev,
        englishBody: selectedTemplate?.enText,
        japaneseBody: selectedTemplate?.jaText,
        englishTitle: selectedTemplate?.enTitle,
        japaneseTitle: selectedTemplate?.jaTitle,
      }))
    }
  }, [notificationType, selectedTemplate])

  const [serverNotification, setServerNotification] = useState({
    startDate: '',
    endDate: '',
    englishText: '',
    japaneseText: '',
  })

  const [appVersionNotification, setAppVersionNotification] = useState({
    appVersion: '',
    englishText: '',
    japaneseText: '',
    englishUpdateBtn: '',
    japaneseUpdateBtn: '',
    englishTitle: '',
    japaneseTitle: '',
  })

  const [otherNotification, setOtherNotification] = useState({
    englishTitle: '',
    japaneseTitle: '',
    englishBody: '',
    japaneseBody: '',
  })

  function convertToUTC(dateString) {
    const date = new Date(dateString)
    // Create a new Date object for today
    const utcTime = date.toISOString().replace(/\.\d{3}Z$/, 'Z')
    return utcTime
  }

  const createNotification = (payload) => {
    if (payload) {
      let tempobj = {
        ...payload,
        startDateTime: convertToUTC(payload.startDateTime),
        endDateTime: convertToUTC(payload.endDateTime),
        notificationTemplateId: selectedTemplate?.id,
      }
      if (payload.specificDateTime) {
        tempobj = {
          ...tempobj,
          specificDateTime: convertToUTC(payload.specificDateTime),
        }
      }
      axiosInstance
        .post('/admin/notification', tempobj)
        .then((res) => {
          setData((prev) => [res.data.data, ...prev])
          setStartDate(new Date())
          setEndDate(new Date())
          setSpecificDateAndTime(new Date())
          setNotificationType('server_maintenance')
          setScheduleNotification('immediately')
        })
        .catch((err) => console.log(err))
    }
  }

  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: 'normal',
        color: '#000',
        background: 'var(--bg-color)',
      },
    },
    headCells: {
      style: {
        borderRight: '1px solid #C4C4C4',
        height: '35px',
      },
    },
  }

  const columns = [
    {
      name: (
        <div className='flex w-full'>
          Type
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: (row) => row.actionType,
      sortable: true,
      minWidth: '100px',
      cell: (row) => (
        <div className='capitalize'>{actionTypeObj[row?.actionType]}</div>
      ),
    },
    {
      name: (
        <div className='flex w-full !max-w-[140px]'>
          Title
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      selector: (row) => row?.notificationTemplate?.enTitle,
      minWidth: '40px',
      cell: (row) => (
        <div className='capitalize'>{row?.notificationTemplate?.enTitle}</div>
      ),
    },
    {
      name: (
        <div className='flex w-full'>
          Created At
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      selector: (row) => row?.createdAt,
      minWidth: '50px',
      cell: (row) => {
        return (
          <div className='capitalize'>
            {utcToDDMMYYYY(row?.createdAt)} {getOnlyTime(row?.createdAt)}
          </div>
        )
      },
    },
    {
      name: (
        <div className='flex w-full'>
          Start Time
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      selector: (row) => row?.startDateTime,
      minWidth: '50px',
      cell: (row) => (
        <div className='capitalize'>
          {row?.actionType === 'server_maintenance'
            ? `${utcToDDMMYYYY(row?.startDateTime)} ${getOnlyTime(
                row?.startDateTime
              )}`
            : '-'}
        </div>
      ),
    },

    {
      name: (
        <div className='flex w-full'>
          End Time
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      selector: (row) => row?.endDateTime,
      minWidth: '50px',
      cell: (row) => (
        <div className='capitalize'>
          {row?.actionType === 'server_maintenance'
            ? `${utcToDDMMYYYY(row?.endDateTime)} ${getOnlyTime(
                row?.startDateTime
              )}`
            : '-'}
        </div>
      ),
    },
  ]

  const handleSubmit = () => {
    let payload = {}
    if (notificationType === 'server_maintenance') {
      payload = {
        actionType: 'server_maintenance',
        startDateTime: startDate,
        endDateTime: endDate,
      }
    }
    if (notificationType === 'app_version_update') {
      payload = {
        actionType: 'app_version_update',
        appVersion: appVersionNotification?.appVersion,
      }
    }
    if (notificationType === 'admin_announcement') {
      payload = {
        actionType: 'admin_announcement',
      }
    }
    if (scheduleNotification === 'specific') {
      payload = {
        ...payload,
        specificDateTime: specificDateAndTime,
      }
    }
    if (scheduleNotification === 'recurring') {
      payload = { ...payload, recurring }
    }
    payload = {
      ...payload,
      scheduleType: scheduleNotification,
      notificationTemplateId: selectedTemplate?.id,
    }
    createNotification(payload)
  }

  return (
    <Page>
      <div className='grid grid-cols-2 gap-4'>
        <div className='shadow-customBtnShadow rounded-2xl'>
          <div className='flex items-center justify-between rounded-2xl bg-bg-universal bg-opacity-10 p-2'>
            <p className='text-black font-semibold font-poppins text-base ml-5'>
              Predefined Notifications
            </p>
            {selectedIds.length > 0 && (
              <button
                onClick={deleteNotification}
                className='px-4 py-2 text-white hover:underline rounded-md hover:bg-blue-600'
                style={{
                  borderRadius: '12px',
                  background: '#00BDB2',
                  boxShadow:
                    '-4px -4px 15px 0px #051569 inset, 3px 3px 12px 0px #7BD9A6 inset',
                  backdropFilter: 'blur(5px)',
                }}
              >
                <GoTrash className='bg-transparent text-white' />
              </button>
            )}
          </div>
          <div className='p-4 '>
            <DataTable
              columns={columns}
              data={data}
              highlightOnHover
              selectableRows
              customStyles={customCellStyles}
              className='custom-data-table'
              onSelectedRowsChange={(row) =>
                setSelectedIds(row?.selectedRows?.map((item) => item.id))
              }
            />
          </div>
        </div>
        <div>
          <div className='shadow-customBtnShadow rounded-2xl mb-7 pb-7'>
            <div className='flex items-center justify-between rounded-2xl bg-bg-universal bg-opacity-10 p-2'>
              <p className='text-black font-semibold font-poppins text-base ml-5'>
                Add Notification
              </p>
              <button
                className='w-20 h-10 text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
            <div className='px-6 py-6 w-full'>
              <p className='text-black font-poppins text-sm font-semibold mb-2 '>
                Notification Type
              </p>
              <CustomSelect
                width='100%'
                // options={data?.position}
                options={notificationTypeArray}
                selectedValue={notificationType}
                onChange={(value) => setNotificationType(value)}
                fullWidth={true}
              />
            </div>
            <div className='px-6 py-6 w-full'>
              <p className='text-black font-poppins text-sm font-semibold mb-2 '>
                Template
              </p>
              <CustomSelect
                width='100%'
                // options={data?.position}
                options={template?.map((temp) => {
                  return {
                    label: temp?.enTitle,
                    value: temp?.id,
                  }
                })}
                selectedValue={selectedTemplate?.enTitle}
                onChange={(value) => {
                  setSelectedTemplate(
                    template?.find((temp) => temp?.id === value)
                  )
                }}
                fullWidth={true}
              />
            </div>
            {notificationType === 'server_maintenance' && (
              <>
                <div className='px-6 py-6'>
                  <div className='flex flex-row justify-between'>
                    <div>
                      <p className='text-black font-poppins text-sm font-semibold'>
                        Start Time
                      </p>
                      <DatePicker
                        wrapperClassName='w-full'
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date)
                        }}
                        showTimeSelect
                        timeFormat='HH:mm'
                        timeIntervals={1}
                        timeCaption='time'
                        dateFormat='MMMM d, yyyy h:mm aa'
                        className='p-3 w-[250px] border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                      />
                    </div>
                    <div>
                      <p className='text-black font-poppins text-sm font-semibold'>
                        End Time
                      </p>
                      <DatePicker
                        wrapperClassName='w-full'
                        selected={endDate}
                        onChange={(date) => {
                          setEndDate(date)
                        }}
                        showTimeSelect
                        timeFormat='HH:mm'
                        timeIntervals={1}
                        timeCaption='time'
                        dateFormat='MMMM d, yyyy h:mm aa'
                        className='p-3 w-[250px] border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                      />
                    </div>
                  </div>
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Predefined Text (In English)
                  </p>
                  <TextArea
                    value={serverNotification.englishText}
                    disabled={true}
                    className='mt-2 !rounded-r-xl'
                    placeHolder='Notification Text (In English)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Predefined Text (In Japanese)
                  </p>
                  <TextArea
                    value={serverNotification.japaneseText}
                    disabled={true}
                    className='mt-2 !rounded-r-xl'
                    placeHolder='Notification Text (In Japanese)'
                  />
                </div>
              </>
            )}
            {notificationType === 'app_version_update' && (
              <>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    App Version
                  </p>
                  <Input
                    value={appVersionNotification.appVersion}
                    onChange={(e) =>
                      setAppVersionNotification((prev) => ({
                        ...prev,
                        appVersion: e.target.value,
                      }))
                    }
                    className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                    type='text'
                    placeHolder='App Version'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Notification Title (In English)
                  </p>
                  <Input
                    disabled={true}
                    value={appVersionNotification.englishTitle}
                    onChange={(e) =>
                      setAppVersionNotification((prev) => ({
                        ...prev,
                        englishTitle: e.target.value,
                      }))
                    }
                    className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                    type='text'
                    placeHolder='Notification Title (In English)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Notification Title (In Japanese)
                  </p>
                  <Input
                    disabled={true}
                    value={appVersionNotification.japaneseTitle}
                    onChange={(e) =>
                      setAppVersionNotification((prev) => ({
                        ...prev,
                        japaneseTitle: e.target.value,
                      }))
                    }
                    className='mt-2 h-10 !border-input-border  !border-solid !border-light-border-search !rounded-r-xl'
                    type='text'
                    placeHolder='Update Button Text (In Japanese)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Predefined Text (In English)
                  </p>
                  <TextArea
                    value={appVersionNotification.englishText}
                    disabled={true}
                    className='mt-2 !rounded-r-xl'
                    placeHolder='Notification Text (In Japanese)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Predefined Text (In Japanese)
                  </p>
                  <TextArea
                    value={appVersionNotification.japaneseText}
                    disabled={true}
                    className='mt-2 !rounded-r-xl'
                    placeHolder='Notification Text (In Japanese)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Update Button Text (In English)
                  </p>
                  <Input
                    disabled={true}
                    value={appVersionNotification.englishUpdateBtn}
                    onChange={(e) =>
                      setAppVersionNotification((prev) => ({
                        ...prev,
                        englishUpdateBtn: e.target.value,
                      }))
                    }
                    className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                    type='text'
                    placeHolder='Update Button Text (In English)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Update Button Text (In Japanese)
                  </p>
                  <Input
                    disabled={true}
                    value={appVersionNotification.japaneseUpdateBtn}
                    onChange={(e) =>
                      setAppVersionNotification((prev) => ({
                        ...prev,
                        japaneseUpdateBtn: e.target.value,
                      }))
                    }
                    className='mt-2 h-10 !border-input-border  !border-solid !border-light-border-search !rounded-r-xl'
                    type='text'
                    placeHolder='Update Button Text (In Japanese)'
                  />
                </div>
              </>
            )}
            {notificationType === 'admin_announcement' && (
              <>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Notification Title (In English)
                  </p>
                  <Input
                    disabled={true}
                    value={otherNotification.englishTitle}
                    onChange={(e) =>
                      setOtherNotification((prev) => ({
                        ...prev,
                        englishTitle: e.target.value,
                      }))
                    }
                    className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                    type='text'
                    placeHolder='Enter Notification Title (In English)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Notification Title (In Japanese)
                  </p>
                  <Input
                    disabled={true}
                    value={otherNotification.japaneseTitle}
                    onChange={(e) =>
                      setOtherNotification((prev) => ({
                        ...prev,
                        japaneseTitle: e.target.value,
                      }))
                    }
                    className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                    type='text'
                    placeHolder='Enter Notification Title (In Japanese)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Notification Body (In English)
                  </p>
                  <TextArea
                    value={otherNotification.englishBody}
                    disabled={true}
                    className='mt-2 !rounded-r-xl'
                    placeHolder='Notification Text (In Japanese)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Notification Body (In Japanese)
                  </p>
                  <TextArea
                    value={otherNotification.japaneseBody}
                    disabled={true}
                    className='mt-2 !rounded-r-xl'
                    placeHolder='Notification Text (In Japanese)'
                  />
                </div>
              </>
            )}

            <div className='px-6 py-6'>
              <p className='text-black font-poppins text-sm font-semibold'>
                Schedule
              </p>
              <div className='flex items-center gap-x-6 mt-2'>
                <GlobalRadioButton
                  label='Immediately'
                  name='shapeGroup'
                  value='square'
                  checked={scheduleNotification === 'immediately'}
                  onChange={() => handleRadioChange('immediately')}
                  shape='md'
                />
                {/* <GlobalRadioButton
                  label='Recurring'
                  name='shapeGroup'
                  value='square'
                  checked={scheduleNotification === 'recurring'}
                  onChange={() => handleRadioChange('recurring')}
                  shape='md'
                /> */}
                <GlobalRadioButton
                  label='Specific Date And Time'
                  name='shapeGroup'
                  value='square'
                  checked={scheduleNotification === 'specific'}
                  onChange={() => handleRadioChange('specific')}
                  shape='md'
                />
              </div>
              {scheduleNotification === 'specific' && (
                <div className='mt-2'>
                  <DatePicker
                    wrapperClassName='w-full'
                    selected={specificDateAndTime}
                    onChange={(date) => setSpecificDateAndTime(date)}
                    showTimeSelect
                    timeFormat='HH:mm'
                    timeIntervals={1}
                    timeCaption='time'
                    dateFormat='MMMM d, yyyy h:mm aa'
                    className='p-3 w-[250px] border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                  />
                </div>
              )}
              {scheduleNotification === 'recurring' && (
                <Input
                  className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search rounded-r-xl'
                  type='email'
                  placeHolder='Schedule'
                  value={recurring}
                  onChange={(e) => setRecurring(e.target.value)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}
